@import '../../../../src/scss/variables';

.timeline-list-container {
  & .timeline-list-header {
    display: flex;
    align-items: center;
    border-bottom: 3px solid $brand-gray-4;

    & h1,
    h2,
    h3,
    h4,
    h5 {
      color: $brand-gray-1;
    }
  }

  & * {
    font-family: $font-family-header;
  }

  & .timeline-list-body {
    display: flex;
    flex-wrap: wrap;

    & .timeline-add-item {
      display: flex;
      align-items: center;
      height: 32px;
      border-bottom: 1px solid $brand-gray-4;

      & * {
        padding: 4px;
      }
    }

    & .timeline-list-item {
      display: flex;
      align-items: center;
      position: relative;

      border-bottom: 1px solid $brand-gray-4;

      & h1,
      h2,
      h3,
      h4,
      h5 {
        color: $brand-gray-1;
      }

      & .comment {
        background-color: transparent;
        border-bottom: groove 2px #ddd;

        &:hover {
          border-bottom: groove 3px $brand-gray-4;
        }
      }

      & .faded {
        position: absolute;
        display: flex;
        align-items: center;
        max-width: 0px;
        height: 100%;
        background-color: $brand-gray-2;
        overflow: hidden;
        top: -99999px;
        padding: 0px;

        &.active {
          max-width: 100%;
          top: inherit;
        }

        transition: max-width 0.5s ease-out;
      }

      & .verification-text {
        flex: 4 0;
        position: relative;
        width: 128px;
        color: white;
        margin-left: 8px;
        margin-right: 8px;
        padding-right: 8px;
        white-space: nowrap;
      }

      & .verification-button {
        flex: 1 0;
        position: relative;
        width: 128px;
        margin-left: 8px;
        margin-right: 8px;
      }

      & * select {
        width: 100%;
        overflow: hidden;
        border: none;
        background-color: transparent;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      & * {
        padding: 4px;
      }

      & .timeline:after {
        content: '';
        position: absolute;
        top: 50%;
        left: calc(50% - 2px);
        width: 4px;
        height: 100%;
        background-color: #000;
      }

      &:last-child {
        & .timeline:after {
          display: none;
        }
      }
    }
  }

  & .timeline {
    flex: 0 0 24px;
    font-size: 20px;
    height: 100%;
    position: relative;
    display: flex;

    &:before {
      display: flex;
      align-items: center;
    }
  }

  & .index {
    flex: 0 0 24px;
  }

  & .add {
    flex: 0 0 28px;
    color: $brand-green;
    font-size: 22px;
    cursor: pointer;
  }

  & .remove {
    flex: 0 0 28px;
    color: $brand-red;
    font-size: 22px;
    cursor: pointer;
  }

  & .collectionDate {
    display: flex;
    flex: 1 0;
    min-width: 190px;

    &.not-valid {
      border: 1px solid #888;
      padding: 0;
    }
  }

  & .scan {
    flex: 1.5 0;
  }

  & .pointcloud {
    flex: 1 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .orthotile {
    flex: 2 0;
  }

  & .comment {
    flex: 3 0;
  }

  & .header {
    font-size: 16px;
    font-weight: bold;
  }

  & .row {
    font-size: 14px;
  }
}
