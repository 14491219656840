#signin {
  div.inputs {
    display: flex;
    flex-direction: column;
    gap: 5px;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    i.fas {
      display: flex;
      width: 30px;
      justify-content: center;
      font-size: 12pt;
      color: #5e6a71;
    }

    input,
    select {
      flex: 1;
    }

    input {
      padding: 5px;
      background-color: #fff !important;
      border: 1px solid #ccc;
    }
  }

  footer {
    margin: 0;
    display: flex;
    justify-content: flex-end;
  }

  .button {
    margin: 20px 0;
  }

  & .info-box-text > b {
    font-weight: 500;
  }
}
